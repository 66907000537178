import React from "react";
import { RouteConfig } from ".";
import { AccountTypeEnum, UserRolesEnum } from "../interfaces";

const AccountHome = React.lazy(() => import("../containers/AccountHome"));
const AccountPartnerSettings = React.lazy(
  () => import("../containers/AccountPartnerSettings")
);
const AccountSocialNetworks = React.lazy(
  () => import("../containers/AccountSocialNetworks")
);
const AccountFeedSettings = React.lazy(
  () => import("../containers/AccountFeedSettings")
);
const AccountUsers = React.lazy(() => import("../containers/AccountUsers"));
const AccountDevices = React.lazy(() => import("../containers/AccountDevices"));
const AccountChangeLicenses = React.lazy(
  () => import("../containers/AccountChangeSubscription")
);
const AccountLicenceOverview = React.lazy(
  () => import("../containers/AccountLicenceOverview")
);

export type AccountRoutesNames =
  | "account_home"
  | "account_partnerSettings"
  | "account_networkConnections"
  | "account_feedSettings"
  | "account_users"
  | "account_devices"
  | "account_roles"
  | "account_change_subscription"
  | "account_licenceOverview"
  | "account_invoices";

const AccountChangeSubscriptionRoute: RouteConfig = {
  name: "account_change_subscription",
  label: "Change Subscription",
  path: "/:slug/account/subscription",
  requiredUserRoles: ["ROLE_OWNER"],
  hideFromMenu: true,
  component: AccountChangeLicenses,
};
const AccountRoutes: RouteConfig[] = [
  {
    name: "account_home",
    label: "Account & Billing",
    path: "/:slug/account/home",
    requiredUserRoles: ["ROLE_OWNER"],
    component: AccountHome,
  },
  {
    name: "account_devices",
    label: "Licenses",
    path: "/:slug/account/licenses",
    requiredUserRoles: ["ROLE_OWNER"],
    restrictOn: ["Distributor"],
    component: AccountDevices,
    hideFromMenu: true,
  },
  {
    name: "account_users",
    label: "Users",
    path: "/:slug/account/users",
    requiredUserRoles: ["ROLE_OWNER"],
    component: AccountUsers,
  },
  {
    name: "account_licenceOverview",
    label: "Licence Overview",
    path: "/:slug/account/licence-overview",
    component: AccountLicenceOverview,
    requiredUserRoles: ["ROLE_OWNER"],
  },
  {
    name: "account_networkConnections",
    label: "feeds.network_connections",
    path: "/:slug/account/network-connections",
    requiredUserRoles: ["ROLE_OWNER", "ROLE_MANAGER"],
    component: AccountSocialNetworks,
  },
  {
    name: "account_feedSettings",
    label: "accounts.global_settings_label",
    path: "/:slug/account/global-settings",
    requiredUserRoles: ["ROLE_OWNER"],
    component: AccountFeedSettings,
  },
  {
    name: "account_partnerSettings",
    label: "Partner settings",
    path: "/:slug/account/partner-settings",
    component: AccountPartnerSettings,
    requiredUserRoles: ["ROLE_OWNER"],
    restrictOn: [AccountTypeEnum.client],
  },
  {
    ...AccountChangeSubscriptionRoute,
    path: "/:slug/account/subscription/:requestedFeature",
  },
  {
    ...AccountChangeSubscriptionRoute,
  },
  {
    name: "redirect",
    label: "",
    path: "/:slug/account/**",
    redirect: true,
    component: () => <span></span>,
  },
];

export default AccountRoutes;
